// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: "https://us-central1-eventapp-2018-dev.cloudfunctions.net",
  dynamicLinkDomain: 'agorifydevelopment.page.link',
  firebase: {
    apiKey: "AIzaSyDxrVBcpvhBreFmNMmuBzl5curL7qhVrfI",
    authDomain: "eventapp-2018-dev.firebaseapp.com",
    databaseURL: "https://eventapp-2018-dev.firebaseio.com",
    projectId: "eventapp-2018-dev",
    storageBucket: "eventapp-2018-dev.appspot.com",
    messagingSenderId: "1098441539347"
  },
  videoBucket: "eventapp-2018-dev-video-upload",
  stripeKey: "pk_test_ReEBMELEhKZYToWbbEsemMx1",
  roomUrl: "https://rooms.devel.agorify.com",
  hostingUrl: "https://development-agorify-live.web.app",
  registrationPageBaseUrl: "https://development-agorify-landing.web.app/e/",
  adminBaseUrl: "https://development-agorify-admin.web.app",
  baseUrl: "https://development-agorify-landing.web.app",
  imageBaseUrl: "https://images.devel.agorify.com",
  backendApiUrl: "https://backend-api.devel.agorify.com",
  //
  // Google Ads Conversion ID
  //
  // If configured, will send a conversion event to Google Ads when a payment is successful from payment-return page.
  //
  googleAdsConversion: null,
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
